import React from 'react';

import CollapsedWrapper from '../styled/CollapsedWrapper';
import { GroupMemberCard, NoMembers } from './GroupMemberCard';

export default ({ item }) => (
  <CollapsedWrapper>
    {
      !!item.groupMembers && item.groupMembers.length
        ? item.groupMembers.map((groupMember) => <GroupMemberCard key={groupMember.uuid} groupMember={groupMember} isChild />)
        : <NoMembers />
    }
  </CollapsedWrapper>
);
