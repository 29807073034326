import React from 'react';

import Layout from '../../components/MenuLayout';
import { GroupsView } from '../../components/Group/GroupsView';

export default () => (
  <Layout>
    <GroupsView />
  </Layout>
);
