import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/react-hooks';
import { Container, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SortIcon from '@material-ui/icons/Sort';

import Box from '@material-ui/core/Box';
import { SectionBar } from '../../componentsUI/SectionBar';
import { isEmpty, sortGraphQueryList } from '../../utils/ObjectUtils';
import { PageListContent } from '../Common/styled/PageContent';
import { CardWrapperUI } from '../Common/styled/CardWrapperUI';
import Loading from '../Common/Loading';
import { GET_HOSPITAL_GROUPS_FOR_PREVIEW } from '../../graphql/queries';
import TableCollapse from '../../componentsUI/TableCollapse';
import GroupCardCollapse from '../Common/GroupCard/GroupCardCollapse';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { ScrollableContainer } from '../Common/ScrollableContainer';

const fieldNames = [
  { label: 'name', id: 'name', field: 'NAME', width: 170 },
  { label: 'users', id: 'groupMembers', field: 'USERS', width: 170, format: 'LENGTH' },
];

export const GroupsView = () => {
  const { t } = useTranslation();
  const uuid = useSelector((state) => state.hospital.uuid);
  const [selected, setSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const sortData = {
    default: { field: 'NAME', direction: 'ASC' },
    fields: [{ key: 'NAME', text: 'Name' }, { key: 'USERS', text: 'Users' }],
  };
  const [orderByField, setOrderByField] = useState(sortData.default.field);
  const [orderByDirection, setOrderByDirection] = useState(sortData.default.direction);

  const { loading, data } = useQuery(GET_HOSPITAL_GROUPS_FOR_PREVIEW, {
    variables: { uuid },
  });

  const reverseDirection = () => (orderByDirection === 'ASC' ? 'DESC' : 'ASC');

  const handleOrderBy = (value) => {
    if (value.field) {
      if (value.field === orderByField) {
        setOrderByDirection(reverseDirection());
      } else {
        setOrderByDirection(value.direction);
      }

      setOrderByField(value.field);
      setAnchorEl();
      setSelected([]);
    }
  };

  const getTitle = (item) => (
    `${item.name}`
  );

  const getSubTitle = (item) => (
    t('userCount', { count: item.groupMembers.length })
  );

  const handleOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const buttons = [
    { name: 'sort.by', icon: SortIcon, handleClick: handleOrder, disabled: false },
  ];

  const OrderIcon = ({ className, direction }) => (
    (direction === 'ASC') ? <ArrowDropDownIcon className={className} /> : <ArrowDropUpIcon className={className} />
  );

  const userGroups = data && data.hospital && sortGraphQueryList(data.hospital.userGroups, fieldNames, orderByField, orderByDirection);

  return (
    <>
      <Navbar>
        <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
          {fieldNames.filter((filter) => !!filter.field).map((item) => (
            <MenuItem
              key={item.id}
              onClick={() => handleOrderBy(item)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ width: 150 }}>{t(item.label)}</span>
              {(item.field === orderByField) && <OrderIcon direction={orderByDirection} />}
            </MenuItem>
          ))}
        </Menu>
        <SectionBar title="groups" items={buttons} />
      </Navbar>

      <Container maxWidth="lg">
        <ScrollableContainer>
          <PageListContent>
            {(loading && isEmpty(data))
              ? <Loading />
              : (
                <CardWrapperUI>
                  {userGroups ? (
                    <TableCollapse
                      fieldNames={fieldNames}
                      items={userGroups}
                      GetTitle={getTitle}
                      GetSubtitle={getSubTitle}
                      GetCollapse={GroupCardCollapse}
                      selected={selected}
                      setSelected={setSelected}
                      order={{ field: orderByField, direction: orderByDirection }}
                      handleOrder={handleOrderBy}
                    />
                  ) : <Box>{t('no.groups')}</Box>}
                </CardWrapperUI>
              )}
          </PageListContent>
        </ScrollableContainer>
      </Container>
    </>
  );
};
